*{
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}
html,body{
    font-weight: 200;
}

.App{
    padding:2rem 0 !important;
}

.banner{
    background-image: url('../images/bg_image3.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position:center;
    height: 780px;
    
}



@media screen and (max-width: 820px){
    .banner {
        height: 950px;
    }
}

@media screen and (max-width: 576px){
    .banner {
        background-color: #204dcf;
        background-image: none;
        height: 900px;
    }
    
    .upcoming_seminars{
        padding: 5px 34px !important;
    }
    
}

/* .footer_bg{
    background-image: url('../images/bg_image2.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position:top;
} */

.trading_pattern{
    /* background-image: url('../images/lines-bg-2.svg'); */
    z-index: 0;
    background-repeat: no-repeat;
    background-color:transparent;
    background-position: top;
    background-size: contain;
}

.steps{
    background-image: url('../images/lines-bg-2.svg');
    background-repeat: no-repeat;
    background-position: top;
    background-size:contain;
    background-color: #204dcf;
}

.coming_soon{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 150px;
}

.tradingview-widget-container{
    width: 700px !important;
    max-width: 100%;
}

/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
    transition: transform 100ms ease-in-out;
    transition-delay: 50ms;
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open{
    transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close{
    transform: scale(0);
    /* transform: translateY(-150%); */
}
.aurum-logo{
    width: 200px;
}
@media screen and (min-width: 520px){
    .aurum-logo{
    	width: 280px !important;
    }
}

h1{
    font-weight: 800;
    font-size: 2.87rem;
}
h2{
    font-size: 1.375rem !important;
    font-weight: 800;  
    color: #0b1320; 
}
.semibold-f{
    font-weight: 600 !important;
}
.extraBold{
    font-weight: 800 !important;
}
/*btn css*/
.bt-round{
    border-radius: 4px !important;
}
.exploreBtn{
  display: inline-flex !important;
  align-items: center;  
  font-size: 1rem !important;
}
.exploreBtn i{
    padding-left: 5px;
}
.exploreBtn i{
    width: 25px;
    padding-top: 5px;
}
.exploreBtn i path{
    fill: #ffffff
}


/*generic css*/
.shadow-box{
    box-shadow: 0 0 40px 0 rgb(0 0 0 / 10%);
}

.reverse-symbol{
    transform: rotate(-180deg);
}
.quotesImg{
    width: 7%;
    height: auto;
}
.v-seperator{
    padding-top: 20px
}

/*Rotating Text*/
.rotate-wrapper{
    position: relative;
    font-size: 1.875rem !important;
    font-weight: 800;
}
.rotate-wrapper span{
    font-size: 1.875rem !important;
    font-weight: 800;
}
.rotate-wrapper .rw-wrapper{
    display: inline-block;
    height: .9em;
    margin: 0;
    padding: 0;
    width: 240px;
    text-align: left;
    padding-left: 13px;
}
.rotate-wrapper .rotating-word:first-of-type {
    opacity: 1;
}
.rotate-wrapper .rotating-word:nth-of-type(2) {
    animation-delay: 4s;
}
.rotate-wrapper .rotating-word:nth-of-type(3) {
    animation-delay: 8s;
}
.rotate-wrapper .rotating-word:nth-of-type(4) {
    animation-delay: 12s;
}
.rotate-wrapper .rotating-word:nth-of-type(5) {
    animation-delay: 16s;
}
.rotate-wrapper .rotating-word{
    animation: word-rotate 20s linear 0s infinite;
    display: block;
    bottom: 0;
    left: 0;
    align-items: flex-start;
    opacity: 0;
    position: absolute;
    right: 0;
    line-height: 1.5;
    font-size: 1.875rem !important;
    font-weight: 800;
    text-align: center;
    color:#44cab1;
}


@keyframes word-rotate{
    0%{opacity:0}
    1%{opacity:0;transform:translateY(30px)}
    2%{opacity:1;transform:translateY(0)}
    14%{opacity:1;transform:translateY(0)}
    15%{opacity:0;transform:translateY(30px)}
    80%{opacity:0}to{opacity:0}
}

@-webkit-keyframes scrollUp {
    0% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-33.33%);
        -ms-transform: translateY(-33.33%);
        transform: translateY(-33.33%);
    }
}
@keyframes scrollUp {
    0% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-33.33%);
        -ms-transform: translateY(-33.33%);
        transform: translateY(-33.33%);
    }
}

/*clients css begins*/
.wr-clients{
    margin: 0 auto;
    padding: 0;
    max-width: 750px;
}
.wr-clients li{
    list-style-type: none;
    display: inline-block;
    text-align: center;
    height: 60px;
    margin: 10px 10px 10px 0;
}
.wr-clients li:last-child{
    margin-right: 0;
}
.wr-clients li img{
    height: 100%;
    margin: 0 auto
}

@media screen and (max-width: 1023px){
    .aurum-navbar{
        position: fixed;
        left:0;
        right: 0;
        top: 0;   
        z-index: 1;
        background-color: #ffffff; 
        padding: 0 20px;
        box-shadow: 0 0 40px 0 rgb(0 0 0 / 10%);
    }    
}
@media screen and (min-width: 576px){
    .rotate-wrapper .rw-wrapper {
        width: 300px;
    }
}
@media screen and (min-width: 639px){
    .rotate-wrapper span, .rotate-wrapper .rotating-word{
        font-size: 1.875rem !important
    }
    h1{
        font-size: 2.25rem;
    }
    h2{
        font-size: 1.875rem !important;
    }
}
@media screen and (min-width: 1024px){
    .rotate-wrapper span, .rotate-wrapper .rotating-word{
        font-size: 2.25rem !important;
        left: auto;
        right: auto;
        display: inline-flex;
        text-align: initial;
    }
    .wr-clients li{
        width: 127px !important;
        margin: 10px 20px 10px 0;
    }
}
.borderResponsive{
    border-bottom: 1px solid #cbd5e0;
}
/*.borderResponsive > div:last-child{
    border-bottom: none;
}*/
@media screen and (min-width: 520px){
    .borderResponsive{
        border-bottom: none;
    }
}
.chatbtn{
    background: #48bb78;
    padding: 4px 6px 4px 6px;
    color:#fff;
    border-radius: 2px;
}
.enrollbtn{
    background: #d8328c;
    padding: 4px 6px 4px 6px;
    color:#fff;
    border-radius: 2px;
}
.btnblock{
    width:100%;
    display: flex;
    flex-direction: row;
    gap:20px;
    justify-content: end;
}

/******NIFTY-INTRADAY TABLE*********/

.happynessText h1{
    font-size: 26px;
}

.nifty_content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: end;
}
.nifty_table{
    justify-content: center;
    align-items: center;
    padding: 30px;
    justify-content: center;
}

.nifty_table .table_content{
    display: flex;
    justify-content: space-between;
    margin-top: 50PX;
    flex-wrap: wrap;
}

.nifty_table table{
    text-align: left;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    border: 1px solid #000;
}

.table_context1{
    width: 70%;
    margin: 0 auto;
}

.table_context2{
    width: 70%;
    margin: 0 auto;
}

.nifty_table table th{
    background-color: #204dcf;
    color: #fff;
    font-weight: 600;
    border-bottom:1px solid #000;
    border-right:1px solid #000;
    padding: 10px;
}

.nifty_table table tr:nth-child(even){
    background-color: #f7f7f7;
    color: #204dcf;
    font-weight: 500;
    border-bottom:1px solid #000;
    border-right:1px solid #000;
    padding: 10px;
}

.nifty_table table td{
    background-color: #f7f7f7;
    color: #204dcf;
    font-weight: 500;
    border-bottom:1px solid #000;
    border-right:1px solid #000;
    padding: 10px;
}

.nifty{
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    color: #204dcf;
    margin:0px 0px 30px 0;
}

.bank_nifty{
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    color: #204dcf;
    margin:0px 0px 30px 0;
    
}

.current_date h4{
    color: #000;
    font-weight: 600;
    text-decoration: underline;
}

.nifty_banknifty_levels{
    width: 70%;
    margin: 0 auto;
}

@media screen and (max-width: 520px) {
    .table_context1{
        width: 100%;
        margin: 0 auto;
        overflow: auto;
    }
    
    .table_context2{
        width: 100%;
        margin: 0 auto;
    }

    .happynessText h1{
        font-size: 26px;
        margin-top: 65px;
    }
    
    .nifty_content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column
    }

    .nifty_banknifty_levels{
        width: 100%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 820px) {
   
    .happynessText h1{
        font-size: 26px;
        margin-top: 85px;
    }
    
    .nifty_content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column
    }
}


/********Upcoming seminar********/

.upcoming_seminar h3{
  font-size: 18px;
  padding-top: 10px;
  color: #204dcf;
}

.upcoming_seminar p{
    font-weight: 500;
    line-height: 25px;
}

.google_form h3{
    font-size: 18px;
    padding-top: 10px;
    color: #204dcf;
}

.google_form p{
    font-weight: 500;
    line-height: 25px;
}

.google_form a{ 
    color: #204dcf;
    font-weight: 600;
}




